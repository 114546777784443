<template lang='pug'>
	.d-flex.justify-content-between.flex-wrap.buy-tokens(:class="{'is-explanation': isExplanationTokens}")
		template(v-if="!isExplanationTokens")
			.buy-tokens__col
				.border-rounded.buy-tokens__wrap(:class="{'is-dark': isDarkTheme}")
					.buy-tokens__inner
						img.flex-shrink-0.buy-tokens__image.buy-tokens__image-1(src="/assets/img/tokens/tokens-1.webp" width="253" height="195" alt="Tokens")
						div
							span.d-block.h3.buy-tokens__quantity
								span {{ `${tokensOneTitle} ` }}
								span.buy-tokens__quantity-name {{ $t('tokens.quantityTokens') }}
							.d-flex.flex-wrap.align-items-center.buy-tokens__prices
								span.text-bold.buy-tokens__price(v-html="currencyRUB ? tokensOneRUB : tokensOneUSD")
								span.b1.buy-tokens__price-old
					span.btn.btn-primary.buy-tokens__button(@click="$emit('buy', {currencyRUB: currencyRUB, product: {name: `${tokensOneTitle} ${$t('tokens.quantityTokens')}`, cost: currencyRUB ? tokensOneRUB : tokensOneUSD}})") {{ $t('forms.buy') }}
					ul.buy-tokens__features
						li.buy-tokens__features-item
							svg.buy-tokens__icon-infinity(width='14' height='7' viewbox='0 0 14 7' fill='none' xmlns='http://www.w3.org/2000/svg')
								path(opacity='0.8' d='M3.452 6.97C2.73333 6.97 2.12667 6.82533 1.632 6.536C1.14667 6.24667 0.782667 5.86867 0.54 5.402C0.297333 4.926 0.176 4.41267 0.176 3.862C0.176 3.33 0.292667 2.83067 0.526 2.364C0.768667 1.888 1.13733 1.50533 1.632 1.216C2.12667 0.917333 2.73333 0.768 3.452 0.768C4.10533 0.768 4.69333 0.95 5.216 1.314C5.73867 1.66867 6.26133 2.16333 6.784 2.798C7.148 2.22867 7.61933 1.748 8.198 1.356C8.77667 0.964 9.36933 0.768 9.976 0.768C10.6947 0.768 11.3013 0.912666 11.796 1.202C12.2907 1.49133 12.6593 1.874 12.902 2.35C13.1447 2.826 13.266 3.33933 13.266 3.89C13.266 4.422 13.1447 4.92133 12.902 5.388C12.6687 5.85467 12.3047 6.23733 11.81 6.536C11.3153 6.82533 10.704 6.97 9.976 6.97C9.36 6.97 8.79533 6.80667 8.282 6.48C7.778 6.15333 7.274 5.70533 6.77 5.136C6.38733 5.67733 5.916 6.12067 5.356 6.466C4.80533 6.802 4.17067 6.97 3.452 6.97ZM10.06 5.5C10.5733 5.5 10.9793 5.33667 11.278 5.01C11.5767 4.674 11.726 4.27267 11.726 3.806C11.726 3.53533 11.6513 3.27867 11.502 3.036C11.362 2.784 11.1567 2.58333 10.886 2.434C10.6247 2.27533 10.3213 2.196 9.976 2.196C9.556 2.196 9.11733 2.364 8.66 2.7C8.212 3.02667 7.876 3.414 7.652 3.862C8.09067 4.394 8.492 4.8 8.856 5.08C9.22933 5.36 9.63067 5.5 10.06 5.5ZM3.452 5.542C3.86267 5.542 4.306 5.402 4.782 5.122C5.26733 4.842 5.64067 4.506 5.902 4.114C5.398 3.498 4.95467 3.036 4.572 2.728C4.19867 2.41067 3.802 2.252 3.382 2.252C2.86867 2.252 2.46267 2.41533 2.164 2.742C1.87467 3.06867 1.73 3.47 1.73 3.946C1.73 4.21667 1.8 4.478 1.94 4.73C2.08 4.97267 2.28067 5.16867 2.542 5.318C2.80333 5.46733 3.10667 5.542 3.452 5.542Z' fill='white')
							span {{ ` GPT-3.5 Turbo` }}
						li.buy-tokens__features-item(v-for='feature in featuresTokens[0]' :key='feature')
							svg.buy-tokens__icon-tilde(width='8' height='4' viewbox='0 0 8 4' fill='none' xmlns='http://www.w3.org/2000/svg')
								path(opacity='0.8' d='M5.18 3.08C4.88133 3.08 4.61067 3.024 4.368 2.912C4.12533 2.8 3.89667 2.67867 3.682 2.548C3.46733 2.41733 3.25733 2.296 3.052 2.184C2.856 2.072 2.65533 2.016 2.45 2.016C2.26333 2.016 2.10467 2.06267 1.974 2.156C1.84333 2.24933 1.73133 2.34733 1.638 2.45C1.53533 2.57133 1.44667 2.71133 1.372 2.87L0.49 2.31C0.611333 2.03933 0.765333 1.79667 0.952 1.582C1.11067 1.39533 1.316 1.22733 1.568 1.078C1.82933 0.919333 2.14667 0.84 2.52 0.84C2.81867 0.84 3.08933 0.896 3.332 1.008C3.57467 1.12 3.80333 1.24133 4.018 1.372C4.23267 1.50267 4.438 1.624 4.634 1.736C4.83 1.848 5.03533 1.904 5.25 1.904C5.43667 1.904 5.59533 1.862 5.726 1.778C5.85667 1.68467 5.96867 1.58667 6.062 1.484C6.16467 1.36267 6.25333 1.218 6.328 1.05L7.21 1.61C7.08867 1.89 6.93467 2.13733 6.748 2.352C6.58933 2.53867 6.37933 2.70667 6.118 2.856C5.85667 3.00533 5.544 3.08 5.18 3.08Z' fill='white')
							span(v-html="` ${feature}`")
			.buy-tokens__col
				.border-rounded.buy-tokens__wrap(:class="{'is-dark': isDarkTheme}")
					.buy-tokens__inner
						img.flex-shrink-0.buy-tokens__image.buy-tokens__image-2(src="/assets/img/tokens/tokens-2.webp" width="253" height="195" alt="Tokens")
						div
							span.d-block.h3.buy-tokens__quantity
								span {{ `${tokensTwoTitle} ` }}
								span.buy-tokens__quantity-name {{ $t('tokens.quantityTokens') }}
							.d-flex.flex-wrap.align-items-center.buy-tokens__prices
								span.text-bold.buy-tokens__price(v-html="currencyRUB ? tokensTwoRUB : tokensTwoUSD")
								span.b1.buy-tokens__price-old
					span.btn.btn-primary.buy-tokens__button(@click="$emit('buy', {currencyRUB: currencyRUB, product: {name: `${tokensTwoTitle} ${$t('tokens.quantityTokens')}`, cost: currencyRUB ? tokensTwoRUB : tokensTwoUSD}})") {{ $t('forms.buy') }}
					ul.buy-tokens__features
						li.buy-tokens__features-item
							svg.buy-tokens__icon-infinity(width='14' height='7' viewbox='0 0 14 7' fill='none' xmlns='http://www.w3.org/2000/svg')
								path(opacity='0.8' d='M3.452 6.97C2.73333 6.97 2.12667 6.82533 1.632 6.536C1.14667 6.24667 0.782667 5.86867 0.54 5.402C0.297333 4.926 0.176 4.41267 0.176 3.862C0.176 3.33 0.292667 2.83067 0.526 2.364C0.768667 1.888 1.13733 1.50533 1.632 1.216C2.12667 0.917333 2.73333 0.768 3.452 0.768C4.10533 0.768 4.69333 0.95 5.216 1.314C5.73867 1.66867 6.26133 2.16333 6.784 2.798C7.148 2.22867 7.61933 1.748 8.198 1.356C8.77667 0.964 9.36933 0.768 9.976 0.768C10.6947 0.768 11.3013 0.912666 11.796 1.202C12.2907 1.49133 12.6593 1.874 12.902 2.35C13.1447 2.826 13.266 3.33933 13.266 3.89C13.266 4.422 13.1447 4.92133 12.902 5.388C12.6687 5.85467 12.3047 6.23733 11.81 6.536C11.3153 6.82533 10.704 6.97 9.976 6.97C9.36 6.97 8.79533 6.80667 8.282 6.48C7.778 6.15333 7.274 5.70533 6.77 5.136C6.38733 5.67733 5.916 6.12067 5.356 6.466C4.80533 6.802 4.17067 6.97 3.452 6.97ZM10.06 5.5C10.5733 5.5 10.9793 5.33667 11.278 5.01C11.5767 4.674 11.726 4.27267 11.726 3.806C11.726 3.53533 11.6513 3.27867 11.502 3.036C11.362 2.784 11.1567 2.58333 10.886 2.434C10.6247 2.27533 10.3213 2.196 9.976 2.196C9.556 2.196 9.11733 2.364 8.66 2.7C8.212 3.02667 7.876 3.414 7.652 3.862C8.09067 4.394 8.492 4.8 8.856 5.08C9.22933 5.36 9.63067 5.5 10.06 5.5ZM3.452 5.542C3.86267 5.542 4.306 5.402 4.782 5.122C5.26733 4.842 5.64067 4.506 5.902 4.114C5.398 3.498 4.95467 3.036 4.572 2.728C4.19867 2.41067 3.802 2.252 3.382 2.252C2.86867 2.252 2.46267 2.41533 2.164 2.742C1.87467 3.06867 1.73 3.47 1.73 3.946C1.73 4.21667 1.8 4.478 1.94 4.73C2.08 4.97267 2.28067 5.16867 2.542 5.318C2.80333 5.46733 3.10667 5.542 3.452 5.542Z' fill='white')
							span {{ ` GPT-3.5 Turbo` }}
						li.buy-tokens__features-item(v-for='feature in featuresTokens[1]' :key='feature')
							svg.buy-tokens__icon-tilde(width='8' height='4' viewbox='0 0 8 4' fill='none' xmlns='http://www.w3.org/2000/svg')
								path(opacity='0.8' d='M5.18 3.08C4.88133 3.08 4.61067 3.024 4.368 2.912C4.12533 2.8 3.89667 2.67867 3.682 2.548C3.46733 2.41733 3.25733 2.296 3.052 2.184C2.856 2.072 2.65533 2.016 2.45 2.016C2.26333 2.016 2.10467 2.06267 1.974 2.156C1.84333 2.24933 1.73133 2.34733 1.638 2.45C1.53533 2.57133 1.44667 2.71133 1.372 2.87L0.49 2.31C0.611333 2.03933 0.765333 1.79667 0.952 1.582C1.11067 1.39533 1.316 1.22733 1.568 1.078C1.82933 0.919333 2.14667 0.84 2.52 0.84C2.81867 0.84 3.08933 0.896 3.332 1.008C3.57467 1.12 3.80333 1.24133 4.018 1.372C4.23267 1.50267 4.438 1.624 4.634 1.736C4.83 1.848 5.03533 1.904 5.25 1.904C5.43667 1.904 5.59533 1.862 5.726 1.778C5.85667 1.68467 5.96867 1.58667 6.062 1.484C6.16467 1.36267 6.25333 1.218 6.328 1.05L7.21 1.61C7.08867 1.89 6.93467 2.13733 6.748 2.352C6.58933 2.53867 6.37933 2.70667 6.118 2.856C5.85667 3.00533 5.544 3.08 5.18 3.08Z' fill='white')
							span(v-html="` ${feature}`")
			.buy-tokens__col
				.d-flex.justify-content-center.align-items-center.buy-tokens__discount.is-orange
					span.b2.text-bold {{ $t('tokens.benefit') }} {{ discountTokensOne }}
				.border-rounded.buy-tokens__wrap(:class="{'is-dark': isDarkTheme}")
					.buy-tokens__inner
						img.flex-shrink-0.buy-tokens__image.buy-tokens__image-3(src="/assets/img/tokens/tokens-3.webp" width="253" height="207" alt="Tokens")
						div
							span.d-block.h3.buy-tokens__quantity
								span {{ `${tokensThreeTitle} ` }}
								span.buy-tokens__quantity-name {{ $t('tokens.quantityTokens') }}
							.d-flex.flex-wrap.align-items-center.buy-tokens__prices
								span.text-bold.buy-tokens__price(v-html="currencyRUB ? tokensThreeRUB : tokensThreeUSD")
								span.b1.buy-tokens__price-old(v-html="currencyRUB ? tokensThreeOldRUB : tokensThreeOldUSD")
					span.btn.btn-primary.buy-tokens__button(@click="$emit('buy', {currencyRUB: currencyRUB, product: {name: `${tokensThreeTitle} ${$t('tokens.quantityTokens')}`, cost: currencyRUB ? tokensThreeRUB : tokensThreeUSD, discount: discountTokensOne, colorDiscount: 'orange', oldCost: currencyRUB ? tokensThreeOldRUB : tokensThreeOldUSD}})") {{ $t('forms.buy') }}
					ul.buy-tokens__features
						li.buy-tokens__features-item
							svg.buy-tokens__icon-infinity(width='14' height='7' viewbox='0 0 14 7' fill='none' xmlns='http://www.w3.org/2000/svg')
								path(opacity='0.8' d='M3.452 6.97C2.73333 6.97 2.12667 6.82533 1.632 6.536C1.14667 6.24667 0.782667 5.86867 0.54 5.402C0.297333 4.926 0.176 4.41267 0.176 3.862C0.176 3.33 0.292667 2.83067 0.526 2.364C0.768667 1.888 1.13733 1.50533 1.632 1.216C2.12667 0.917333 2.73333 0.768 3.452 0.768C4.10533 0.768 4.69333 0.95 5.216 1.314C5.73867 1.66867 6.26133 2.16333 6.784 2.798C7.148 2.22867 7.61933 1.748 8.198 1.356C8.77667 0.964 9.36933 0.768 9.976 0.768C10.6947 0.768 11.3013 0.912666 11.796 1.202C12.2907 1.49133 12.6593 1.874 12.902 2.35C13.1447 2.826 13.266 3.33933 13.266 3.89C13.266 4.422 13.1447 4.92133 12.902 5.388C12.6687 5.85467 12.3047 6.23733 11.81 6.536C11.3153 6.82533 10.704 6.97 9.976 6.97C9.36 6.97 8.79533 6.80667 8.282 6.48C7.778 6.15333 7.274 5.70533 6.77 5.136C6.38733 5.67733 5.916 6.12067 5.356 6.466C4.80533 6.802 4.17067 6.97 3.452 6.97ZM10.06 5.5C10.5733 5.5 10.9793 5.33667 11.278 5.01C11.5767 4.674 11.726 4.27267 11.726 3.806C11.726 3.53533 11.6513 3.27867 11.502 3.036C11.362 2.784 11.1567 2.58333 10.886 2.434C10.6247 2.27533 10.3213 2.196 9.976 2.196C9.556 2.196 9.11733 2.364 8.66 2.7C8.212 3.02667 7.876 3.414 7.652 3.862C8.09067 4.394 8.492 4.8 8.856 5.08C9.22933 5.36 9.63067 5.5 10.06 5.5ZM3.452 5.542C3.86267 5.542 4.306 5.402 4.782 5.122C5.26733 4.842 5.64067 4.506 5.902 4.114C5.398 3.498 4.95467 3.036 4.572 2.728C4.19867 2.41067 3.802 2.252 3.382 2.252C2.86867 2.252 2.46267 2.41533 2.164 2.742C1.87467 3.06867 1.73 3.47 1.73 3.946C1.73 4.21667 1.8 4.478 1.94 4.73C2.08 4.97267 2.28067 5.16867 2.542 5.318C2.80333 5.46733 3.10667 5.542 3.452 5.542Z' fill='white')
							span {{ ` GPT-3.5 Turbo` }}
						li.buy-tokens__features-item(v-for='feature in featuresTokens[2]' :key='feature')
							svg.buy-tokens__icon-tilde(width='8' height='4' viewbox='0 0 8 4' fill='none' xmlns='http://www.w3.org/2000/svg')
								path(opacity='0.8' d='M5.18 3.08C4.88133 3.08 4.61067 3.024 4.368 2.912C4.12533 2.8 3.89667 2.67867 3.682 2.548C3.46733 2.41733 3.25733 2.296 3.052 2.184C2.856 2.072 2.65533 2.016 2.45 2.016C2.26333 2.016 2.10467 2.06267 1.974 2.156C1.84333 2.24933 1.73133 2.34733 1.638 2.45C1.53533 2.57133 1.44667 2.71133 1.372 2.87L0.49 2.31C0.611333 2.03933 0.765333 1.79667 0.952 1.582C1.11067 1.39533 1.316 1.22733 1.568 1.078C1.82933 0.919333 2.14667 0.84 2.52 0.84C2.81867 0.84 3.08933 0.896 3.332 1.008C3.57467 1.12 3.80333 1.24133 4.018 1.372C4.23267 1.50267 4.438 1.624 4.634 1.736C4.83 1.848 5.03533 1.904 5.25 1.904C5.43667 1.904 5.59533 1.862 5.726 1.778C5.85667 1.68467 5.96867 1.58667 6.062 1.484C6.16467 1.36267 6.25333 1.218 6.328 1.05L7.21 1.61C7.08867 1.89 6.93467 2.13733 6.748 2.352C6.58933 2.53867 6.37933 2.70667 6.118 2.856C5.85667 3.00533 5.544 3.08 5.18 3.08Z' fill='white')
							span(v-html="` ${feature}`")

			.buy-tokens__col
				.d-flex.justify-content-center.align-items-center.buy-tokens__discount.is-green
					span.b2.text-bold {{ $t('tokens.benefit') }} {{ discountTokensTwo }}
				.border-rounded.buy-tokens__wrap(:class="{'is-dark': isDarkTheme}")
					.buy-tokens__inner
						img.flex-shrink-0.buy-tokens__image.buy-tokens__image-4(src="/assets/img/tokens/tokens-4.webp" width="253" height="208" alt="Tokens")
						div
							span.d-block.h3.buy-tokens__quantity
								span {{ `${tokensFourTitle} ` }}
								span.buy-tokens__quantity-name {{ $t('tokens.quantityTokens') }}
							.d-flex.flex-wrap.align-items-center.buy-tokens__prices
								span.text-bold.buy-tokens__price(v-html="currencyRUB ? tokensFourRUB : tokensFourUSD")
								span.b1.buy-tokens__price-old(v-html="currencyRUB ? tokensFourOldRUB : tokensFourOldUSD")
					span.btn.btn-primary.buy-tokens__button(@click="$emit('buy', {currencyRUB: currencyRUB, product: {name: `${tokensFourTitle} ${$t('tokens.quantityTokens')}`, cost: currencyRUB ? tokensFourRUB : tokensFourUSD, discount: discountTokensTwo, colorDiscount: 'green', oldCost: currencyRUB ? tokensFourOldRUB : tokensFourOldUSD}})") {{ $t('forms.buy') }}
					ul.buy-tokens__features
						li.buy-tokens__features-item
							svg.buy-tokens__icon-infinity(width='14' height='7' viewbox='0 0 14 7' fill='none' xmlns='http://www.w3.org/2000/svg')
								path(opacity='0.8' d='M3.452 6.97C2.73333 6.97 2.12667 6.82533 1.632 6.536C1.14667 6.24667 0.782667 5.86867 0.54 5.402C0.297333 4.926 0.176 4.41267 0.176 3.862C0.176 3.33 0.292667 2.83067 0.526 2.364C0.768667 1.888 1.13733 1.50533 1.632 1.216C2.12667 0.917333 2.73333 0.768 3.452 0.768C4.10533 0.768 4.69333 0.95 5.216 1.314C5.73867 1.66867 6.26133 2.16333 6.784 2.798C7.148 2.22867 7.61933 1.748 8.198 1.356C8.77667 0.964 9.36933 0.768 9.976 0.768C10.6947 0.768 11.3013 0.912666 11.796 1.202C12.2907 1.49133 12.6593 1.874 12.902 2.35C13.1447 2.826 13.266 3.33933 13.266 3.89C13.266 4.422 13.1447 4.92133 12.902 5.388C12.6687 5.85467 12.3047 6.23733 11.81 6.536C11.3153 6.82533 10.704 6.97 9.976 6.97C9.36 6.97 8.79533 6.80667 8.282 6.48C7.778 6.15333 7.274 5.70533 6.77 5.136C6.38733 5.67733 5.916 6.12067 5.356 6.466C4.80533 6.802 4.17067 6.97 3.452 6.97ZM10.06 5.5C10.5733 5.5 10.9793 5.33667 11.278 5.01C11.5767 4.674 11.726 4.27267 11.726 3.806C11.726 3.53533 11.6513 3.27867 11.502 3.036C11.362 2.784 11.1567 2.58333 10.886 2.434C10.6247 2.27533 10.3213 2.196 9.976 2.196C9.556 2.196 9.11733 2.364 8.66 2.7C8.212 3.02667 7.876 3.414 7.652 3.862C8.09067 4.394 8.492 4.8 8.856 5.08C9.22933 5.36 9.63067 5.5 10.06 5.5ZM3.452 5.542C3.86267 5.542 4.306 5.402 4.782 5.122C5.26733 4.842 5.64067 4.506 5.902 4.114C5.398 3.498 4.95467 3.036 4.572 2.728C4.19867 2.41067 3.802 2.252 3.382 2.252C2.86867 2.252 2.46267 2.41533 2.164 2.742C1.87467 3.06867 1.73 3.47 1.73 3.946C1.73 4.21667 1.8 4.478 1.94 4.73C2.08 4.97267 2.28067 5.16867 2.542 5.318C2.80333 5.46733 3.10667 5.542 3.452 5.542Z' fill='white')
							span {{ ` GPT-3.5 Turbo` }}
						li.buy-tokens__features-item(v-for='feature in featuresTokens[3]' :key='feature')
							svg.buy-tokens__icon-tilde(width='8' height='4' viewbox='0 0 8 4' fill='none' xmlns='http://www.w3.org/2000/svg')
								path(opacity='0.8' d='M5.18 3.08C4.88133 3.08 4.61067 3.024 4.368 2.912C4.12533 2.8 3.89667 2.67867 3.682 2.548C3.46733 2.41733 3.25733 2.296 3.052 2.184C2.856 2.072 2.65533 2.016 2.45 2.016C2.26333 2.016 2.10467 2.06267 1.974 2.156C1.84333 2.24933 1.73133 2.34733 1.638 2.45C1.53533 2.57133 1.44667 2.71133 1.372 2.87L0.49 2.31C0.611333 2.03933 0.765333 1.79667 0.952 1.582C1.11067 1.39533 1.316 1.22733 1.568 1.078C1.82933 0.919333 2.14667 0.84 2.52 0.84C2.81867 0.84 3.08933 0.896 3.332 1.008C3.57467 1.12 3.80333 1.24133 4.018 1.372C4.23267 1.50267 4.438 1.624 4.634 1.736C4.83 1.848 5.03533 1.904 5.25 1.904C5.43667 1.904 5.59533 1.862 5.726 1.778C5.85667 1.68467 5.96867 1.58667 6.062 1.484C6.16467 1.36267 6.25333 1.218 6.328 1.05L7.21 1.61C7.08867 1.89 6.93467 2.13733 6.748 2.352C6.58933 2.53867 6.37933 2.70667 6.118 2.856C5.85667 3.00533 5.544 3.08 5.18 3.08Z' fill='white')
							span(v-html="` ${feature}`")

			.border-rounded.buy-tokens__can-do(:class="{'is-dark': isDarkTheme}" @click="$emit('openExplanation')")
				span.b1.text-bold.buy-tokens__can-do-txt {{ $t('tokens.canYouDo') }}
				svg(width='24' height='24' viewbox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg')
					path(d='M8.91016 19.9201L15.4302 13.4001C16.2002 12.6301 16.2002 11.3701 15.4302 10.6001L8.91016 4.08008' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')

		.border-rounded.buy-tokens__explanation(v-if="isExplanationTokens")
			ul.buy-tokens__features
				li.buy-tokens__features-item
					svg.buy-tokens__icon-infinity(width='16' height='8' viewbox='0 0 16 8' fill='none' xmlns='http://www.w3.org/2000/svg')
						path(opacity='0.8' d='M4.088 7.68C3.26667 7.68 2.57333 7.51467 2.008 7.184C1.45333 6.85333 1.03733 6.42133 0.76 5.888C0.482667 5.344 0.344 4.75733 0.344 4.128C0.344 3.52 0.477333 2.94933 0.744 2.416C1.02133 1.872 1.44267 1.43467 2.008 1.104C2.57333 0.762666 3.26667 0.592 4.088 0.592C4.83467 0.592 5.50667 0.8 6.104 1.216C6.70133 1.62133 7.29867 2.18667 7.896 2.912C8.312 2.26133 8.85067 1.712 9.512 1.264C10.1733 0.816 10.8507 0.592 11.544 0.592C12.3653 0.592 13.0587 0.757333 13.624 1.088C14.1893 1.41867 14.6107 1.856 14.888 2.4C15.1653 2.944 15.304 3.53067 15.304 4.16C15.304 4.768 15.1653 5.33867 14.888 5.872C14.6213 6.40533 14.2053 6.84267 13.64 7.184C13.0747 7.51467 12.376 7.68 11.544 7.68C10.84 7.68 10.1947 7.49333 9.608 7.12C9.032 6.74667 8.456 6.23467 7.88 5.584C7.44267 6.20267 6.904 6.70933 6.264 7.104C5.63467 7.488 4.90933 7.68 4.088 7.68ZM11.64 6C12.2267 6 12.6907 5.81333 13.032 5.44C13.3733 5.056 13.544 4.59733 13.544 4.064C13.544 3.75467 13.4587 3.46133 13.288 3.184C13.128 2.896 12.8933 2.66667 12.584 2.496C12.2853 2.31467 11.9387 2.224 11.544 2.224C11.064 2.224 10.5627 2.416 10.04 2.8C9.528 3.17333 9.144 3.616 8.888 4.128C9.38933 4.736 9.848 5.2 10.264 5.52C10.6907 5.84 11.1493 6 11.64 6ZM4.088 6.048C4.55733 6.048 5.064 5.888 5.608 5.568C6.16267 5.248 6.58933 4.864 6.888 4.416C6.312 3.712 5.80533 3.184 5.368 2.832C4.94133 2.46933 4.488 2.288 4.008 2.288C3.42133 2.288 2.95733 2.47467 2.616 2.848C2.28533 3.22133 2.12 3.68 2.12 4.224C2.12 4.53333 2.2 4.832 2.36 5.12C2.52 5.39733 2.74933 5.62133 3.048 5.792C3.34667 5.96267 3.69333 6.048 4.088 6.048Z' fill='white')
					span {{ ` GPT-3.5 Turbo` }}
				li.buy-tokens__features-item(v-for='feature in featuresTokens[2]' :key='feature')
					svg.buy-tokens__icon-tilde(width='9' height='4' viewbox='0 0 9 4' fill='none' xmlns='http://www.w3.org/2000/svg')
						path(opacity='0.8' d='M5.92 3.52C5.57867 3.52 5.26933 3.456 4.992 3.328C4.71467 3.2 4.45333 3.06133 4.208 2.912C3.96267 2.76267 3.72267 2.624 3.488 2.496C3.264 2.368 3.03467 2.304 2.8 2.304C2.58667 2.304 2.40533 2.35733 2.256 2.464C2.10667 2.57067 1.97867 2.68267 1.872 2.8C1.75467 2.93867 1.65333 3.09867 1.568 3.28L0.56 2.64C0.698667 2.33067 0.874667 2.05333 1.088 1.808C1.26933 1.59467 1.504 1.40267 1.792 1.232C2.09067 1.05067 2.45333 0.96 2.88 0.96C3.22133 0.96 3.53067 1.024 3.808 1.152C4.08533 1.28 4.34667 1.41867 4.592 1.568C4.83733 1.71733 5.072 1.856 5.296 1.984C5.52 2.112 5.75467 2.176 6 2.176C6.21333 2.176 6.39467 2.128 6.544 2.032C6.69333 1.92533 6.82133 1.81333 6.928 1.696C7.04533 1.55733 7.14667 1.392 7.232 1.2L8.24 1.84C8.10133 2.16 7.92533 2.44267 7.712 2.688C7.53067 2.90133 7.29067 3.09333 6.992 3.264C6.69333 3.43467 6.336 3.52 5.92 3.52Z' fill='white')
					span(v-html="` ${feature}`")

</template>

<script>
export default {
	name: 'BuyTokens',
	props: {
		isDarkTheme: {
			type: Boolean,
			default: false
		},
		isExplanationTokens: {
			type: Boolean,
			default: false
		},
		plans: {
			type: Array,
			default: () => ([])
		},
		currencyRUB: {
			type: Number,
			default: 0
		}
	},
	data: () => ({
		featuresTokens: null
	}),
	created() {
		this.featuresTokens = this.$t('tokens.featuresTokens');
	},
	computed: {
		tokensOneRUB() {
			return this.plans.find(x => x.tokens == this.tokenCoins[0] && x.isCurrencyRUB()).price.priceWithCurrency();
		},
		tokensTwoRUB() {
			return this.plans.find(x => x.title != 'Monthly subscription' && x.title != 'Yearly subscription' && x.tokens == this.tokenCoins[1] && x.isCurrencyRUB()).price.priceWithCurrency();
		},
		tokensThreeRUB() {
			return this.plans.find(x => x.tokens == this.tokenCoins[2] && x.isCurrencyRUB()).price.priceWithCurrency();
		},
		tokensThreeOldRUB() {
			return this.plans.find(x => x.tokens == this.tokenCoins[2] && x.isCurrencyRUB()).priceFull.priceWithCurrency();
		},
		tokensFourRUB() {
			return this.plans.find(x => x.tokens == this.tokenCoins[3] && x.isCurrencyRUB()).price.priceWithCurrency();
		},
		tokensFourOldRUB() {
			return this.plans.find(x => x.tokens == this.tokenCoins[3] && x.isCurrencyRUB()).priceFull.priceWithCurrency();
		},
		tokensOneUSD() {
			return this.plans.find(x => x.tokens == this.tokenCoins[0] && !x.isCurrencyRUB()).price.priceWithCurrency();
		},
		tokensTwoUSD() {
			return this.plans.find(x => x.title != 'Monthly subscription' && x.title != 'Yearly subscription' && x.tokens == this.tokenCoins[1] && !x.isCurrencyRUB()).price.priceWithCurrency();
		},
		tokensThreeUSD() {
			return this.plans.find(x => x.tokens == this.tokenCoins[2] && !x.isCurrencyRUB()).price.priceWithCurrency();
		},
		tokensThreeOldUSD() {
			return this.plans.find(x => x.tokens == this.tokenCoins[2] && !x.isCurrencyRUB()).priceFull.priceWithCurrency();
		},
		tokensFourUSD() {
			return this.plans.find(x => x.tokens == this.tokenCoins[3] && !x.isCurrencyRUB()).price.priceWithCurrency();
		},
		tokensFourOldUSD() {
			return this.plans.find(x => x.tokens == this.tokenCoins[3] && !x.isCurrencyRUB()).priceFull.priceWithCurrency();
		},
		/*Title plan amount*/
		tokensOneTitle() {
			return this.plans.find(x => x.tokens == this.tokenCoins[0]).tokens;
		},
		tokensTwoTitle() {
			return this.plans.find(x => x.title != 'Yearly subscription' && x.tokens == this.tokenCoins[1]).tokens;
		},
		tokensThreeTitle() {
			return this.plans.find(x => x.tokens == this.tokenCoins[2]).tokens.toLocaleString('ru');
		},
		tokensFourTitle() {
			return this.plans.find(x => x.tokens == this.tokenCoins[3]).tokens.toLocaleString('ru');
		}
	}
}

</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.buy-tokens {
	margin-top: 23px;

	@include respond-below(lg) {
		margin-top: -19px;
		margin-right: 24.5px;
		margin-left: 24.5px;
	}

	@media (max-width: 768px) {
		margin-top: -20px;
		margin-bottom: 34.5px;
		margin-right: 12.5px;
		margin-left: 12.5px;
	}

	@include respond-below(xs) {
		max-width: 420px;
		margin: 0 auto;
	}

	&.is-explanation {
		@include respond-below(xs) {
			width: 100%;
		}
	}

	&__col {
		position: relative;
		width: calc(100% / 4 - (3 / 4 * 24px));

		@include respond-below(lg) {
			width: calc(100% / 2 - (1 / 2 * 43px));
			margin-top: 43px;
		}

		@media (max-width: 650px) {
			width: calc(100% / 2 - (1 / 2 * 28px));
		}

		@include respond-below(xs) {
			width: 100%;
			margin-top: 25px;

			&:has(.buy-tokens__discount) {
				.buy-tokens__wrap {
					border-radius: 0 0 8px 8px !important;
				}
			}
		}
	}

	&__wrap {
		height: 100%;
		padding: 20px;
		background-color: rgba(#ffffff, .2);

		@include respond-below(xs) {
			border-radius: 8px !important;
			padding: 16px 17px;
			height: auto;
		}

		&.is-dark {
			background-color: rgba(#000000, .2);
		}
	}

	&__inner {
		@include respond-below(xs) {
			display: flex;
			flex-direction: row-reverse;
			align-items: center;
		}
	}

	&__discount {
		position: absolute;
		top: -15px;
		left: 0;
		height: 34px;
		width: 100%;
		text-align: center;
		border-radius: 8px 8px 0px 0px;
		color: var(--foreground-color);

		@include respond-below(xs) {
			position: relative;
			top: 1px;
		}

		&.is-orange {
			background-color: #FCB64D;
		}

		&.is-green {
			background-color: #59CB94;
		}
	}

	&__image {
		@include respond-below(lg) {
			margin-left: auto;
			margin-right: auto;
			display: block;
		}

		@include respond-below(sm) {
			height: auto;
		}

		@include respond-below(xs) {
			margin-right: 0;
		}

		&-1, &-2 {
			@include respond-below(sm) {
				width: 214px;
				margin-top: -15px;
				margin-bottom: -27px;
			}

			@include respond-below(xs) {
				width: 157px;
			}
		}

		&-1 {
			@include respond-below(xs) {
				margin-right: -30px;
				margin-top: -53px;
			}
		}

		&-2 {
			@include respond-below(xs) {
				margin-right: -29px;
				margin-top: -43px;
			}
		}

		&-3 {
			margin-bottom: -12px;

			@include respond-below(sm) {
				width: 179px;
				margin-top: -10px;
				margin-bottom: -14px;
			}

			@include respond-below(xs) {
				width: 141px;
				margin-right: -14px;
				margin-top: -26px;
				margin-bottom: 10px;
			}
		}
		&-4 {
			margin-bottom: -13px;

			@include respond-below(sm) {
				width: 159px;
				margin-top: 1px;
				margin-bottom: -9px;
			}

			@include respond-below(xs) {
				width: 137px;
				margin-top: -16px;
				margin-bottom: 3px;
				margin-right: -12px;
			}
		}
	}

	&__quantity {
		margin-top: 10px !important;
		color: #fff;

		@include respond-below(xs) {
			margin-top: 0 !important;
		}

		&-name {
			@include respond-below(xs) {
				font-size: 14px;
				color: #CBD2D8;
			}
		}
	}

	&__prices {
		margin-top: 16px;
		margin-bottom: 31px;

		@include respond-below(xs) {
			margin-bottom: 25px;
		}
	}

	&__price {
		font-size: 36px;
		line-height: 28px;
		margin-right: 12px;
		color: #fff;

		@media (max-width: 650px) {
			font-size: 24px;
			line-height: 28px;
		}

		&-old {
			position: relative;
			display: block;
			color: #fff;

			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				display: block;
				background: center / contain no-repeat url("data:image/svg+xml,%3Csvg width='58' height='14' viewBox='0 0 58 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 12.5L56.5 1' stroke='%23FF7C7C' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
			}
		}
	}

	&__button {
		color: var(--foreground-color) !important;

		@include respond-below(sm) {
			height: 50px;
		}
	}

	&__features {
		margin-top: 30px;
		margin-bottom: 0;
		padding: 0;
		list-style-type: none;
		color: rgba(#fff, .8);

		@include respond-below(xs) {
			display: none;
			margin-top: 0;
		}

		&-item {
			font-size: 14px;
			line-height: 28px;

			@include respond-below(xs) {
				font-size: 16px;
				line-height: 40px;
			}
		}
	}

	&__icon {
		&-infinity {
			margin-left: 2px;
			margin-bottom: 3.5px;
		}

		&-tilde {
			margin-left: 0.5px;
			margin-bottom: 3px;
		}
	}

	&__can-do {
		display: none;
		background-color: rgba(255, 255, 255, 0.2);
		padding: 15px 17px;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		margin-top: 25px;

		@include respond-below(xs) {
			display: flex;
		}

		&.is-dark {
			background-color: rgba(#000000, .2);
		}

		&-txt {
			color: white;
			max-width: 207px;
		}
	}

	&__explanation {
		background-color: rgba(255, 255, 255, 0.2);
		padding: 15px 17px;
		width: 100%;

		.buy-tokens__features {
			display: block;
		}
	}
}

</style>
