<template lang='pug'>
	.payment-tariffs(v-if="plans")
		.border-rounded.tariffs__left-inner(:class="{'is-year': periodYear}")
			.d-flex.justify-content-between.flex-wrap.align-items-center.border-rounded.tariffs__left-header(:class="{'is-year': periodYear}")

				.d-flex.align-items-center.tariffs__left-periods
					span.b3(:class="{'text-grey': periodYear}") {{ $t('date.month') }}
					switches.ml-10.mr-10(v-model="periodYear" class="switches-tariffs")
					span.b3(:class="{'text-grey': !periodYear}") {{ $t('date.year') }}
					span.tariffs__discount {{ discountNeuro }}

				.justify-content-between.tariffs__tabs-periods
					.d-flex.justify-content-center.align-items-center.border-rounded.tariffs__tab(:class="{'active': !periodYear}" @click="periodYear = false")
						span.b3 {{ $t('date.month') }}

					.d-flex.justify-content-center.align-items-center.border-rounded.d-flex.align-items-center.tariffs__tab(:class="{'active': periodYear}" @click="periodYear = true")
						span.b3 {{ $t('date.year') }}
						span.tariffs__discount {{ discountNeuro }}

				b-tabs.tariffs__tabs-currency(v-model="currencyRUB" nav-class='tabs-filled tabs-filled-transparent p-0 tabs-tariffs' no-fade)
					b-tab
						template(v-slot:title)
							.d-flex.align-items-center
								span USD
								img.tariffs__tab-icon(src="/assets/img/icons/american-flag.png" alt="American Flag" width="15" height="auto")

					b-tab(active)
						template(v-slot:title)
							.d-flex.align-items-center
								span RUB
								img.tariffs__tab-icon(src="/assets/img/icons/russian-flag.png" alt="Russian Flag" width="15" height="auto")

			.d-flex.justify-content-between.flex-wrap.tariffs__left-content
				.d-flex.flex-column.justify-content-between.tariffs__subscription
					div
						h3.h3.text-bold.tariffs__subtitle {{ periodYear ? $t('tariffs.subPeriod.year') : $t('tariffs.subPeriod.month') }}

						.d-flex.align-items-center.flex-wrap
							span.text-primary.text-bold.mr-10.tariffs__price(:class="{'is-year': periodYear}" v-html="currencyRUB ? (periodYear ? RUBYear : RUBMonth) : (periodYear ? USDYear : USDMonth)")
							b.text-grey.tariffs__price-old(v-if="periodYear" v-html="currencyRUB ? RUBYearOld : USDYearOld")
							span.b3.text-grey {{ periodYear ? $t('plan.periods.year') : $t('plan.periods.month') }}

						span.b3.text-grey.mt-10.d-block.tariffs__note {{ periodYear ? $t('tariffs.noteYear') : $t('tariffs.noteMonth') }}
						span.btn.btn-primary.tariffs__pay(:class="{'is-year': periodYear}" @click="$emit('pay', {isTrial: false, periodYear: periodYear, currencyRUB: currencyRUB})") {{ $t('forms.pay') }}
						span.btn.tariffs__toggle(@click="toggleCurrency") {{ currencyRUB ? $t('forms.payInUSD') : $t('forms.payInRUS') }}
						span.b4.text-grey.d-block.text-center {{ $t('h1.or') }}
						span.btn.btn-border.tariffs__trial(@click="$emit('trial')") 🎁 {{ $t('tariffs.trial') }}

					.tariffs__subscription-bottom
						p.text-grey.mb-10.mt-10.tariffs__txt(v-if="currencyRUB")
							img.mr-1.mb-1.tariffs__icon-flag(src="/assets/img/icons/russian-flag.png" alt="Russian Flag" width="11" height="auto")
							span(v-html="$t('tariffs.russianCards')")
							span.cursor-pointer.text-underline(@click="currencyRUB = 0" v-html="`${$t('tariffs.russianCardsHere')}.`")
						
						p.text-grey.tariffs__rules-agreement(v-html="currencyRUB ? $t('tariffs.rulesAgreementRu') : $t('tariffs.rulesAgreement')")

				.foreground-color.border-rounded.tariffs__left-info
					div
						span.b3.tariffs__neural.tariffs__neural-chatGPT(:class="{'is-dark': isDarkTheme}") OpenAI ChatGPT 3.5 / 4o
						span.b3.tariffs__neural.tariffs__neural-dallE(:class="{'is-dark': isDarkTheme}") OpenAI DALL-E 3
						span.b3.tariffs__neural.tariffs__neural-diffusion(:class="{'is-dark': isDarkTheme}") Stable Diffusion 1 / 3

					ul.list-unstyled.p-0.m-0.tariffs__benefits
						li.b3.text-grey(v-for='(benefit, i) in benefits' :key="`benefit-${i}`" :class="{'is-dark': isDarkTheme, 'is-year': periodYear}") {{ benefit }}

			.tariffs__add-info
				p.text-grey.mb-10.mt-10.b4(v-if="currencyRUB")
					img.mr-1.mb-1.tariffs__icon-flag(src="/assets/img/icons/russian-flag.png" alt="Russian Flag" width="11" height="auto")
					span(v-html="$t('tariffs.russianCards')")
					span.cursor-pointer.text-underline(@click="currencyRUB = 0" v-html="`${$t('tariffs.russianCardsHere')}.`")
				p.text-grey.b4.mb-0(v-html="currencyRUB ? $t('tariffs.rulesAgreementRu') : $t('tariffs.rulesAgreement')")

</template>

<script>
import Switches from "vue-switches";

export default {
	name: 'PaymentTariffsBot',
	components: {
		Switches
	},
	props: {
		plans: {
			type: Array,
			default: () => ([])
		},
		isDarkTheme: {
			type: Boolean,
			default: false
		},
	},
	data: () => ({
		periodYear: false,
		currencyRUB: 0,
		benefits: null,
	}),
	computed: {
		RUBYear() {
			return this.plans.find(x => x.title == 'Yearly subscription' && x.isCurrencyRUB()).price.priceWithCurrency();
		},
		RUBYearOld() {
			return this.plans.find(x => x.title == 'Yearly subscription' && x.isCurrencyRUB()).priceFull.priceWithCurrency();
		},
		USDYear() {
			return this.plans.find(x => x.title == 'Yearly subscription' && !x.isCurrencyRUB()).price.priceWithCurrency();
		},
		USDYearOld() {
			return this.plans.find(x => x.title == 'Yearly subscription' && !x.isCurrencyRUB()).priceFull.priceWithCurrency();
		},
		RUBMonth() {
			return this.plans.find(x => x.title == 'Monthly subscription' && x.isCurrencyRUB()).price.priceWithCurrency();
		},
		USDMonth() {
			return this.plans.find(x => x.title == 'Monthly subscription' && !x.isCurrencyRUB()).price.priceWithCurrency();
		}
	},
	created() {
		if (this.isLocaleRu) {
			this.currencyRUB == 0
			this.$emit('currencyRUB', 0)
		} else {
			this.currencyRUB == 1
			this.$emit('currencyRUB', 1)
		}

		this.benefits = this.$t('tariffs.benefits');
	},
	methods: {
		toggleCurrency() {
			if(this.currencyRUB == 0){
				this.currencyRUB = 1;
			} else {
				this.currencyRUB = 0;
			}
		},
		thousandsSeparator(value) {
			if (typeof value !== "number") {
				console.log('Не число')
				return value;
			}
			return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
		}
	},
	watch: {
		currencyRUB(nV) {
			if (nV == 0) {
				this.$emit('currencyRUB', 0)
			} else if (nV == 1) {
				this.$emit('currencyRUB', 1)
			}
		}
	}
}

</script>
