<template lang='pug'>
	.foreground-color.basic-box-shadow.border-rounded.payment-trial
		.payment-trial__steps
			.d-flex.align-items-center.payment-trial__step
				.payment-trial__step-icon.is-active
					svg(width='60' height='60' viewbox='0 0 60 60' fill='none' xmlns='http://www.w3.org/2000/svg')
						rect(width='60' height='60' rx='30' :fill="isDarkTheme ? '#50695D' : '#E2F9EE'" fill-opacity='0.5')
						rect(x='9' y='9' width='42' height='42' rx='21' :fill="isDarkTheme ? '#323A36' : '#D4F2E3'")
						path(d='M25.4173 39.166C21.7507 39.166 20.834 38.2493 20.834 34.5827V32.7493C20.834 29.0827 21.7507 28.166 25.4173 28.166L34.584 28.166C38.2507 28.166 39.1673 29.0827 39.1673 32.7493V34.5827C39.1673 38.2493 38.2507 39.166 34.584 39.166H25.4173Z' stroke='#39B67A' stroke-width='2' stroke-linecap='round' stroke-linejoin='round')
						path(d='M24.5 28.1673V26.334C24.5 23.2998 25.4167 20.834 30 20.834C34.125 20.834 35.5 22.6673 35.5 25.4173' stroke='#39B67A' stroke-width='2' stroke-linecap='round' stroke-linejoin='round')
						path(fill-rule='evenodd' clip-rule='evenodd' d='M30.0007 35.9583C28.735 35.9583 27.709 34.9323 27.709 33.6667C27.709 32.401 28.735 31.375 30.0007 31.375C31.2663 31.375 32.2923 32.401 32.2923 33.6667C32.2923 34.9323 31.2663 35.9583 30.0007 35.9583Z' stroke='#39B67A' stroke-width='2' stroke-linecap='round' stroke-linejoin='round')
						path(opacity='0.01' fill-rule='evenodd' clip-rule='evenodd' d='M19 41L19 19L41 19L41 41L19 41Z' stroke='#39B67A' stroke-width='2')
				.payment-trial__step-inner
					span.h3.text-bold.payment-trial__step-title.is-active(v-html="$t('bot.trialPeriod.step1.title')")
					p.m-0.text-grey.b3(v-html="$t('bot.trialPeriod.step1.txt')")
			.d-flex.align-items-center.payment-trial__step
				.payment-trial__step-icon.is-second
					svg(width='42' height='42' viewbox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg')
						rect(width='42' height='42' rx='21' :fill="isDarkTheme ? '#3F3F3F' : '#F0F0F1'")
						path(d='M15.518 18.168L15.518 20.8171C15.518 21.3763 15.2797 22.2288 14.9955 22.7055L13.9414 24.4563C13.2905 25.538 13.7397 26.7388 14.9314 27.1421C18.8822 28.4621 23.1447 28.4621 27.0955 27.1421C28.2047 26.7755 28.6905 25.4646 28.0855 24.4563L27.0314 22.7055C26.7564 22.2288 26.518 21.3763 26.518 20.8171V18.168C26.518 15.143 24.043 12.668 21.018 12.668C17.9839 12.668 15.518 15.1338 15.518 18.168Z' :stroke="isDarkTheme ? '#EAEAEA' : '#082846'" stroke-width='2' stroke-linecap='round')
						path(d='M21.8339 12.749C20.9539 12.639 20.1106 12.7032 19.3223 12.9323C19.5881 12.254 20.2481 11.7773 21.0181 11.7773C21.7881 11.7773 22.4481 12.254 22.7139 12.9323C22.4298 12.8498 22.1364 12.7857 21.8339 12.749Z' :stroke="isDarkTheme ? '#EAEAEA' : '#082846'" stroke-width='2' stroke-linecap='round' stroke-linejoin='round')
						path(d='M23.7676 27.4727C23.7676 28.9852 22.5301 30.2227 21.0176 30.2227C20.2659 30.2227 19.5692 29.911 19.0742 29.416C18.5792 28.921 18.2676 28.2243 18.2676 27.4727' :stroke="isDarkTheme ? '#EAEAEA' : '#082846'" stroke-width='2')
				.payment-trial__step-inner
					span.h3.text-bold.payment-trial__step-title(v-html="$t('bot.trialPeriod.step2.title')")
					p.m-0.text-grey.b3(v-html="$t('bot.trialPeriod.step2.txt')")
			.d-flex.align-items-center.payment-trial__step
				.payment-trial__step-icon
					svg(width='42' height='42' viewbox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg')
						rect(width='42' height='42' rx='21' :fill="isDarkTheme ? '#3F3F3F' : '#F0F0F1'")
						path(d='M24.1988 16.4441C24.4188 16.8933 25.0055 17.3241 25.5005 17.4066L28.4247 17.8924C30.2947 18.2041 30.7347 19.5608 29.3872 20.8991L27.1138 23.1724C26.7288 23.5574 26.518 24.2999 26.6372 24.8316L27.288 27.6458C27.8013 29.8733 26.6188 30.7349 24.648 29.5708L21.9072 27.9483C21.4122 27.6549 20.5963 27.6549 20.0922 27.9483L17.3513 29.5708C15.3897 30.7349 14.198 29.8641 14.7113 27.6458L15.3622 24.8316C15.4813 24.2999 15.2705 23.5574 14.8855 23.1724L12.6122 20.8991C11.2738 19.5608 11.7047 18.2041 13.5747 17.8924L16.4988 17.4066C16.9847 17.3241 17.5713 16.8933 17.7913 16.4441L19.4047 13.2174C20.2847 11.4666 21.7147 11.4666 22.5855 13.2174L24.1988 16.4441Z' :stroke="isDarkTheme ? '#EAEAEA' : '#082846'" stroke-width='2' stroke-linecap='round' stroke-linejoin='round')
				.payment-trial__step-inner
					span.h3.text-bold.payment-trial__step-title(v-html="$t('bot.trialPeriod.step3.title')")
					p.m-0.text-grey.b3(v-html="step3Txt")

		p.m-0.b3.text-center.payment-trial__step-txt(v-html="cost")

		div.text-grey.text-bold.text-center.border-rounded.payment-trial__note {{ $t('bot.trialPeriod.note') }}

		button.w-100.btn.btn-success.b2.payment-trial__button(type="button" @click="$emit('getFreeAccess', {isTrial: true, periodYear: true, currencyRUB: currencyRUB})") {{ $t('bot.trialPeriod.getFreeAccess') }}
		.w100.d-flex.justify-content-center
			button.btn.b2.btn-text.payment-trial__all-tariffs(type="button" @click="$emit('allTariffs')") {{ $t('bot.trialPeriod.allTariffs') }}
</template>

<script>
import moment from 'moment-timezone';

export default {
	name: 'PaymentTrialBot',
	props: {
		isDarkTheme: {
			type: Boolean,
			default: false
		},
		plans: {
			type: Array,
			default: () => ([])
		},
		currencyRUB: {
			type: Number,
			default: 0
		}
	},
	computed: {
		trialUntil() {
			return moment().add(3, 'days').format('D MMMM YYYY');
		},
		RUBYear() {
			return this.plans.find(x => x.title == 'Yearly subscription' && x.isCurrencyRUB()).price.priceWithCurrency();
		},
		USDYear() {
			return this.plans.find(x => x.title == 'Yearly subscription' && !x.isCurrencyRUB()).price.priceWithCurrency();
		},
		RUBMonth() {
			return this.plans.find(x => x.title == 'Monthly subscription' && x.isCurrencyRUB()).price.priceWithCurrency();
		},
		USDMonth() {
			return this.plans.find(x => x.title == 'Monthly subscription' && !x.isCurrencyRUB()).price.priceWithCurrency();
		},
		step3Txt() {
			return this.$t('bot.trialPeriod.step3.txt')
			.replace('%until%', this.trialUntil)
			.replace('%price%', this.currencyRUB == 1 ? this.RUBYear : this.USDYear);
		},
		cost() {
			return this.$t('bot.trialPeriod.cost')
			.replace('%price%', this.currencyRUB == 1 ? this.RUBYear : this.USDYear)
			.replace('%priceMonth%', this.currencyRUB == 1 ? this.RUBMonth : this.USDMonth);
		}
	}
}
</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

	.payment-trial {
		padding: 20px;

		@include respond-below(xs){
			padding: 12px 21px 20px;
		}

		&__steps {
			margin-bottom: 61px;

			@include respond-below(xs){
				margin-bottom: 75px;
			}
		}

		&__step {
			margin-bottom: 17px;
			position: relative;

			@include respond-below(xs){
				align-items: flex-start !important;
			}

			&-inner {
				@include respond-below(xs){
					margin-bottom: -5px;
				}
			}

			&-icon {
				margin-right: 15px;

				svg {
					position: relative;
					z-index: 1;
				}

				&:not(.is-active){
					@include respond-below(xs){
						margin-top: 8px;
					}
				}

				&.is-active {
					margin-left: -10px;
					margin-top: -1px;

					&:after {
						content: '';
						display: block;
						width: 10px;
						height: 125px;
						background-color: var(--brand-bg-active-step);
						position: absolute;
						bottom: -75px;
						left: 15px;
						z-index: 0;
					}
				}

				&.is-second {
					&:before {
						content: '';
						display: block;
						width: 10px;
						height: calc(100% + 42px);
						background-color: var(--brand-bg-step);
						position: absolute;
						top: 0px;
						left: 15px;
						z-index: 0;

						@include respond-below(xs){
							height: calc(100% + 68px);
							top: -26px;
						}
					}
				}
			}

			&-title {
				display: block;
				margin-bottom: 6px !important;

				@include respond-below(xs){
					margin-top: 8px !important;
					margin-bottom: 5px !important;
				}

				&.is-active {
					color: #59CB94;
				}
			}

			&-txt {
				max-width: 345px;
				margin: 0 auto !important;
			}
		}

		&__note {
			padding: 10px 9px;
			background-color: var(--bg-back);
			margin-top: 20px;
			margin-bottom: 20px;
			font-size: 14px;
			line-height: 22px;

			@include respond-below(xs){
				padding: 10px 10px;
			}
		}

		&__button {
			background: linear-gradient(91.92deg, #59CB94 17.14%, #0FBF8A 85.93%) !important;
			color: var(--foreground-color) !important;
			transition: box-shadow 0.4s ease;
			height: 50px;

			&:hover {
				box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.3);
			}

			&:active {
				box-shadow: inset 0px 1px 2px var(--brand-hard-success) !important;
			}

			&:focus {
				box-shadow: none !important;
			}
		}

		&__all-tariffs.btn {
			margin-top: 20px;
			color: var(--brand-success) !important;
			-webkit-text-fill-color: currentColor !important;

			&:hover, &:active {
				background: none;
				color: var(--brand-hard-success) !important;
				outline: none;
			}

			&:focus {
				box-shadow: none;
			}
		}
	}

</style>
